import { default as manufacturerRXSSxV6u7WMeta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/analytics/pages/analytics/manufacturer.vue?macro=true";
import { default as materialConsumptionCutkp70NhSiCXMeta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/analytics/pages/analytics/materialConsumptionCut.vue?macro=true";
import { default as materialConsumptionEdgeBOCzE4uuHoMeta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/analytics/pages/analytics/materialConsumptionEdge.vue?macro=true";
import { default as indexXYPtx2eKltMeta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/analytics/pages/analytics/pay/index.vue?macro=true";
import { default as pdfM7t1nraQB8Meta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/analytics/pages/analytics/pay/pdf.vue?macro=true";
import { default as indexM2jAY92PNWMeta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/analytics/pages/analytics/payBuild/index.vue?macro=true";
import { default as pdf7Bkn8DQE5jMeta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/analytics/pages/analytics/payBuild/pdf.vue?macro=true";
import { default as indexZaBhI7Gy7EMeta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/analytics/pages/analytics/payFacade/index.vue?macro=true";
import { default as pdfYss1DYhMRmMeta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/analytics/pages/analytics/payFacade/pdf.vue?macro=true";
import { default as index90abaIHeeAMeta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/analytics/pages/analytics/payMarriageBulkhead/index.vue?macro=true";
import { default as pdfEvhIEERondMeta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/analytics/pages/analytics/payMarriageBulkhead/pdf.vue?macro=true";
import { default as indexEExRgR7BxXMeta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/analytics/pages/analytics/payMirror/index.vue?macro=true";
import { default as pdfmSYkWwC7cAMeta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/analytics/pages/analytics/payMirror/pdf.vue?macro=true";
import { default as stock1jDHqgAvMIMeta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/analytics/pages/analytics/stock.vue?macro=true";
import { default as indexPmLH2h2xZzMeta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/build/pages/build/index.vue?macro=true";
import { default as indexK4apjQnCS8Meta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/calc/pages/calc/index.vue?macro=true";
import { default as index8MXMZiQ8ccMeta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/crm/pages/crm/index.vue?macro=true";
import { default as dashboard6LPcanJceuMeta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/pages/dashboard.vue?macro=true";
import { default as editZHK1W6YTskMeta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/dictionaries/products/pages/dictionary/products/[id]/edit.vue?macro=true";
import { default as archiveuwvwZ5ECUgMeta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/dictionaries/products/pages/dictionary/products/archive.vue?macro=true";
import { default as createiSJ3M0lUMdMeta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/dictionaries/products/pages/dictionary/products/create.vue?macro=true";
import { default as indexjfWPr6oBc9Meta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/dictionaries/products/pages/dictionary/products/index.vue?macro=true";
import { default as indexDTGGgcMz17Meta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/dictionaries/productsKoroba/pages/dictionary/productsKoroba/index.vue?macro=true";
import { default as index4ltRW5nQRdMeta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/dictionaries/productsMetal/pages/dictionary/productsMetal/index.vue?macro=true";
import { default as editImSVeU9IWTMeta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/dictionaries/sheetFormats/pages/dictionary/sheetFormats/[id]/edit.vue?macro=true";
import { default as archiveATg2gEKBEbMeta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/dictionaries/sheetFormats/pages/dictionary/sheetFormats/archive.vue?macro=true";
import { default as createTvdOIUQeKKMeta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/dictionaries/sheetFormats/pages/dictionary/sheetFormats/create.vue?macro=true";
import { default as indexe1Ng8a7y1cMeta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/dictionaries/sheetFormats/pages/dictionary/sheetFormats/index.vue?macro=true";
import { default as editzNrtWz97c2Meta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/dictionaries/stocks/pages/dictionary/stocks/[id]/edit.vue?macro=true";
import { default as archivexwtrgBt5G3Meta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/dictionaries/stocks/pages/dictionary/stocks/archive.vue?macro=true";
import { default as create3YH6BGDtQuMeta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/dictionaries/stocks/pages/dictionary/stocks/create.vue?macro=true";
import { default as indexTcDaTLFYHsMeta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/dictionaries/stocks/pages/dictionary/stocks/index.vue?macro=true";
import { default as indexDX41DGqTQOMeta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/dictionaries/stocksKoroba/pages/dictionary/stocksKoroba/index.vue?macro=true";
import { default as indexocidSaWGm4Meta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/dictionaries/stocksMetal/pages/dictionary/stocksMetal/index.vue?macro=true";
import { default as indexz0EnGBCVZmMeta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/pages/index.vue?macro=true";
import { default as create2hZCPv2tCHMeta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/manufacturers/pages/manufacturers/create.vue?macro=true";
import { default as indexEIKMPDiSZSMeta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/marriageBulkhead/pages/marriageBulkhead/index.vue?macro=true";
import { default as indexfsWPxZVgGJMeta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/mirror/pages/mirror/index.vue?macro=true";
import { default as periodsnja9ZxyR17Meta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/pages/periods.vue?macro=true";
import { default as edit3rjpHs5agHMeta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/pricePolicy/pages/pricePolicy/[id]/edit.vue?macro=true";
import { default as createQgw5WOHiHzMeta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/pricePolicy/pages/pricePolicy/create.vue?macro=true";
import { default as indexhQ3veqXG9oMeta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/pricePolicy/pages/pricePolicy/index.vue?macro=true";
import { default as indexcYsDMGh9HJMeta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/profile/pages/profile/source/index.vue?macro=true";
import { default as editIEfoErDE7EMeta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/roles/pages/roles/[id]/edit.vue?macro=true";
import { default as createH1LYKVgPAhMeta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/roles/pages/roles/create.vue?macro=true";
import { default as indexmAAG1GppqcMeta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/roles/pages/roles/index.vue?macro=true";
import { default as createmz0XHJiJj7Meta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/stock/pages/stock/create.vue?macro=true";
import { default as indexGmU3HAHMEwMeta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/stock/pages/stock/index.vue?macro=true";
import { default as indexgdSDgmeT67Meta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/tasks/pages/tasks/index.vue?macro=true";
import { default as editk5UhPvuWASMeta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/users/pages/users/[id]/edit.vue?macro=true";
import { default as archiveMJKmeDIYv3Meta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/users/pages/users/archive.vue?macro=true";
import { default as createFzpafSDplKMeta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/users/pages/users/create.vue?macro=true";
import { default as indexxBxrwOsuPhMeta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/users/pages/users/index.vue?macro=true";
import { default as indexKsU9lGaDHaMeta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/workPlans/pages/workPlans/[id]/index.vue?macro=true";
export default [
  {
    name: "analytics-manufacturer",
    path: "/analytics/manufacturer",
    meta: manufacturerRXSSxV6u7WMeta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/analytics/pages/analytics/manufacturer.vue")
  },
  {
    name: "analytics-materialConsumptionCut",
    path: "/analytics/materialConsumptionCut",
    meta: materialConsumptionCutkp70NhSiCXMeta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/analytics/pages/analytics/materialConsumptionCut.vue")
  },
  {
    name: "analytics-materialConsumptionEdge",
    path: "/analytics/materialConsumptionEdge",
    meta: materialConsumptionEdgeBOCzE4uuHoMeta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/analytics/pages/analytics/materialConsumptionEdge.vue")
  },
  {
    name: "analytics-pay",
    path: "/analytics/pay",
    meta: indexXYPtx2eKltMeta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/analytics/pages/analytics/pay/index.vue")
  },
  {
    name: "analytics-pay-pdf",
    path: "/analytics/pay/pdf",
    meta: pdfM7t1nraQB8Meta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/analytics/pages/analytics/pay/pdf.vue")
  },
  {
    name: "analytics-payBuild",
    path: "/analytics/payBuild",
    meta: indexM2jAY92PNWMeta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/analytics/pages/analytics/payBuild/index.vue")
  },
  {
    name: "analytics-payBuild-pdf",
    path: "/analytics/payBuild/pdf",
    meta: pdf7Bkn8DQE5jMeta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/analytics/pages/analytics/payBuild/pdf.vue")
  },
  {
    name: "analytics-payFacade",
    path: "/analytics/payFacade",
    meta: indexZaBhI7Gy7EMeta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/analytics/pages/analytics/payFacade/index.vue")
  },
  {
    name: "analytics-payFacade-pdf",
    path: "/analytics/payFacade/pdf",
    meta: pdfYss1DYhMRmMeta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/analytics/pages/analytics/payFacade/pdf.vue")
  },
  {
    name: "analytics-payMarriageBulkhead",
    path: "/analytics/payMarriageBulkhead",
    meta: index90abaIHeeAMeta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/analytics/pages/analytics/payMarriageBulkhead/index.vue")
  },
  {
    name: "analytics-payMarriageBulkhead-pdf",
    path: "/analytics/payMarriageBulkhead/pdf",
    meta: pdfEvhIEERondMeta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/analytics/pages/analytics/payMarriageBulkhead/pdf.vue")
  },
  {
    name: "analytics-payMirror",
    path: "/analytics/payMirror",
    meta: indexEExRgR7BxXMeta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/analytics/pages/analytics/payMirror/index.vue")
  },
  {
    name: "analytics-payMirror-pdf",
    path: "/analytics/payMirror/pdf",
    meta: pdfmSYkWwC7cAMeta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/analytics/pages/analytics/payMirror/pdf.vue")
  },
  {
    name: "analytics-stock",
    path: "/analytics/stock",
    meta: stock1jDHqgAvMIMeta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/analytics/pages/analytics/stock.vue")
  },
  {
    name: "build",
    path: "/build",
    meta: indexPmLH2h2xZzMeta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/build/pages/build/index.vue")
  },
  {
    name: "calc",
    path: "/calc",
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/calc/pages/calc/index.vue")
  },
  {
    name: "crm",
    path: "/crm",
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/crm/pages/crm/index.vue")
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: dashboard6LPcanJceuMeta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/pages/dashboard.vue")
  },
  {
    name: "dictionary-products-id-edit",
    path: "/dictionary/products/:id()/edit",
    meta: editZHK1W6YTskMeta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/dictionaries/products/pages/dictionary/products/[id]/edit.vue")
  },
  {
    name: "dictionary-products-archive",
    path: "/dictionary/products/archive",
    meta: archiveuwvwZ5ECUgMeta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/dictionaries/products/pages/dictionary/products/archive.vue")
  },
  {
    name: "dictionary-products-create",
    path: "/dictionary/products/create",
    meta: createiSJ3M0lUMdMeta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/dictionaries/products/pages/dictionary/products/create.vue")
  },
  {
    name: "dictionary-products",
    path: "/dictionary/products",
    meta: indexjfWPr6oBc9Meta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/dictionaries/products/pages/dictionary/products/index.vue")
  },
  {
    name: "dictionary-productsKoroba",
    path: "/dictionary/productsKoroba",
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/dictionaries/productsKoroba/pages/dictionary/productsKoroba/index.vue")
  },
  {
    name: "dictionary-productsMetal",
    path: "/dictionary/productsMetal",
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/dictionaries/productsMetal/pages/dictionary/productsMetal/index.vue")
  },
  {
    name: "dictionary-sheetFormats-id-edit",
    path: "/dictionary/sheetFormats/:id()/edit",
    meta: editImSVeU9IWTMeta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/dictionaries/sheetFormats/pages/dictionary/sheetFormats/[id]/edit.vue")
  },
  {
    name: "dictionary-sheetFormats-archive",
    path: "/dictionary/sheetFormats/archive",
    meta: archiveATg2gEKBEbMeta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/dictionaries/sheetFormats/pages/dictionary/sheetFormats/archive.vue")
  },
  {
    name: "dictionary-sheetFormats-create",
    path: "/dictionary/sheetFormats/create",
    meta: createTvdOIUQeKKMeta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/dictionaries/sheetFormats/pages/dictionary/sheetFormats/create.vue")
  },
  {
    name: "dictionary-sheetFormats",
    path: "/dictionary/sheetFormats",
    meta: indexe1Ng8a7y1cMeta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/dictionaries/sheetFormats/pages/dictionary/sheetFormats/index.vue")
  },
  {
    name: "dictionary-stocks-id-edit",
    path: "/dictionary/stocks/:id()/edit",
    meta: editzNrtWz97c2Meta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/dictionaries/stocks/pages/dictionary/stocks/[id]/edit.vue")
  },
  {
    name: "dictionary-stocks-archive",
    path: "/dictionary/stocks/archive",
    meta: archivexwtrgBt5G3Meta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/dictionaries/stocks/pages/dictionary/stocks/archive.vue")
  },
  {
    name: "dictionary-stocks-create",
    path: "/dictionary/stocks/create",
    meta: create3YH6BGDtQuMeta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/dictionaries/stocks/pages/dictionary/stocks/create.vue")
  },
  {
    name: "dictionary-stocks",
    path: "/dictionary/stocks",
    meta: indexTcDaTLFYHsMeta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/dictionaries/stocks/pages/dictionary/stocks/index.vue")
  },
  {
    name: "dictionary-stocksKoroba",
    path: "/dictionary/stocksKoroba",
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/dictionaries/stocksKoroba/pages/dictionary/stocksKoroba/index.vue")
  },
  {
    name: "dictionary-stocksMetal",
    path: "/dictionary/stocksMetal",
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/dictionaries/stocksMetal/pages/dictionary/stocksMetal/index.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexz0EnGBCVZmMeta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/pages/index.vue")
  },
  {
    name: "manufacturers-create",
    path: "/manufacturers/create",
    meta: create2hZCPv2tCHMeta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/manufacturers/pages/manufacturers/create.vue")
  },
  {
    name: "marriageBulkhead",
    path: "/marriageBulkhead",
    meta: indexEIKMPDiSZSMeta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/marriageBulkhead/pages/marriageBulkhead/index.vue")
  },
  {
    name: "mirror",
    path: "/mirror",
    meta: indexfsWPxZVgGJMeta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/mirror/pages/mirror/index.vue")
  },
  {
    name: "periods",
    path: "/periods",
    meta: periodsnja9ZxyR17Meta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/pages/periods.vue")
  },
  {
    name: "pricePolicy-id-edit",
    path: "/pricePolicy/:id()/edit",
    meta: edit3rjpHs5agHMeta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/pricePolicy/pages/pricePolicy/[id]/edit.vue")
  },
  {
    name: "pricePolicy-create",
    path: "/pricePolicy/create",
    meta: createQgw5WOHiHzMeta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/pricePolicy/pages/pricePolicy/create.vue")
  },
  {
    name: "pricePolicy",
    path: "/pricePolicy",
    meta: indexhQ3veqXG9oMeta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/pricePolicy/pages/pricePolicy/index.vue")
  },
  {
    name: "profile-source",
    path: "/profile/source",
    meta: indexcYsDMGh9HJMeta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/profile/pages/profile/source/index.vue")
  },
  {
    name: "roles-id-edit",
    path: "/roles/:id()/edit",
    meta: editIEfoErDE7EMeta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/roles/pages/roles/[id]/edit.vue")
  },
  {
    name: "roles-create",
    path: "/roles/create",
    meta: createH1LYKVgPAhMeta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/roles/pages/roles/create.vue")
  },
  {
    name: "roles",
    path: "/roles",
    meta: indexmAAG1GppqcMeta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/roles/pages/roles/index.vue")
  },
  {
    name: "stock-create",
    path: "/stock/create",
    meta: createmz0XHJiJj7Meta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/stock/pages/stock/create.vue")
  },
  {
    name: "stock",
    path: "/stock",
    meta: indexGmU3HAHMEwMeta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/stock/pages/stock/index.vue")
  },
  {
    name: "tasks",
    path: "/tasks",
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/tasks/pages/tasks/index.vue")
  },
  {
    name: "users-id-edit",
    path: "/users/:id()/edit",
    meta: editk5UhPvuWASMeta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/users/pages/users/[id]/edit.vue")
  },
  {
    name: "users-archive",
    path: "/users/archive",
    meta: archiveMJKmeDIYv3Meta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/users/pages/users/archive.vue")
  },
  {
    name: "users-create",
    path: "/users/create",
    meta: createFzpafSDplKMeta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/users/pages/users/create.vue")
  },
  {
    name: "users",
    path: "/users",
    meta: indexxBxrwOsuPhMeta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/users/pages/users/index.vue")
  },
  {
    name: "workPlans-id",
    path: "/workPlans/:id()",
    meta: indexKsU9lGaDHaMeta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/workPlans/pages/workPlans/[id]/index.vue")
  }
]